export const countryOptions = [
    { value: 'LATAM', label: 'LATAM' },
    { value: 'United States', label: 'United States' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Chile', label: 'Chile' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Mexico', label: 'México' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Panama', label: 'Panamá' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Perú' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Venezuela', label: 'Venezuela' },
];