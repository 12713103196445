import React from 'react';
import { FaLaptopCode, FaGraduationCap, FaLanguage, FaMoneyBillWave } from 'react-icons/fa'; // Iconos para tecnología, educación, idiomas y salario

const CandidateDetails = ({ candidate }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    {/* Rol */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Role</h3>
      <p className="text-blue-500 flex items-center space-x-2">
        <FaLaptopCode className="text-lg" />
        <span>{candidate.role}</span>
      </p>
    </div>

    {/* Seniority */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Seniority</h3>
      <p className="text-gray-900">{candidate.technicalLevel}</p>
    </div>

    {/* Years of experience */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Years of Experience</h3>
      <p className="text-gray-900">{candidate.yearsOfExperience}</p>
    </div>

    {/* Main Technology */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Main Technology</h3>
      <p className="text-blue-500 flex items-center space-x-2">
        <FaLaptopCode className="text-lg" />
        <span>{candidate.mainTech?.join(', ')}</span>
      </p>
    </div>

    {/* Secondary Technology */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Secondary Technology</h3>
      <p className="text-blue-500 flex items-center space-x-2">
        <FaLaptopCode className="text-lg" />
        <span>{candidate.secondaryTech?.join(', ')}</span>
      </p>
    </div>

    {/* Education */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Education</h3>
      <p className="text-gray-900 flex items-center space-x-2">
        <FaGraduationCap className="text-lg" />
        <span>{candidate.educationalLevel}</span>
      </p>
    </div>

    {/* Certifications */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Certifications</h3>
      <p className="text-gray-900">{candidate.certifications}</p>
    </div>

    {/* English level */}
    <div className="space-y-2">
      <h3 className="text-gray-500">English Level</h3>
      <p className="text-gray-900 flex items-center space-x-2">
        <FaLanguage className="text-lg" />
        <span>{candidate.englishLevel}</span>
      </p>
    </div>

    {/* Other languages */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Other Languages</h3>
      <p className="text-gray-900">{candidate.languages?.join(', ')}</p>
    </div>

    {/* Availability for projects */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Availability for Projects</h3>
      <p className="text-blue-500 flex items-center space-x-2">
        <FaLaptopCode className="text-lg" />
        <span>{candidate.contractType}</span>
      </p>
    </div>

    {/* Working scheme */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Working Scheme</h3>
      <p className="text-blue-500">{candidate.workScheme}</p>
    </div>

    {/* Contract terms */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Contract Terms</h3>
      <p className="text-blue-500">{candidate.contractScheme}</p>
    </div>

    {/* Salary expectations */}
    <div className="space-y-2">
      <h3 className="text-gray-500">Salary Expectations</h3>
      <p className="text-gray-900 flex items-center space-x-2">
        <FaMoneyBillWave className="text-lg" />
        <span>{`$${candidate.salaryExpectations}`}</span>
      </p>
    </div>
  </div>
);

export default CandidateDetails;
