import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from "../../assets/Logo/Logo-signin.jpeg";
import SignInHeader from '../../components/SignIn/SignInHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { token } = useParams();
    const navigate = useNavigate();


    const validatePassword = (password, password2) => {
        let isError = false;
        let incorrect = '';
        let passwordTrim = password.trim();
    
        const regexNumber = /\d/; 
        const regexLetter = /[a-zA-Z]/; 
    
        if (!regexNumber.test(passwordTrim)) {
            incorrect = "The password must have at least one number.";
            isError = true;
        } else if (!regexLetter.test(passwordTrim)) {
            incorrect = "The password must have at least one letter.";
            isError = true;
        } else if (password.length < 6) {
            incorrect = "The password must be longer than 6 characters.";
            isError = true;
        } else if (password !== password2) {
            incorrect = "Passwords do not match.";
            isError = true;
        }
    
        return isError ? incorrect : null;
    };
    

    const handleChangePassword = async (event) => {
        event.preventDefault()

        const err = validatePassword(newPassword, confirmPassword);

        if (err === null) {
            try {
                setError('');

                const formData = {
                    password: newPassword,
                    password2: confirmPassword,
                }

                const response = await axios.put(`/reset-password/${token}`, formData);
                if (response.status === 200) {
                    toast.success(`Your password was successfully updated`, {
                        position: "top-center",
                        autoClose: 3000,
                        onOpen: () =>{
                            setNewPassword(''),
                            setConfirmPassword('')
                        },
                        onClose: () => {
                            navigate('/signin');
                        }
                    });
                } 
                
            } catch (error) {
                if (error.response) {
                    toast.error(error.response.data.error, {
                        position: "top-center"
                    });
                } else {
                    toast.error("An error occurred in the request. Please try again.", {
                        position: "top-center"
                    });
                }
            }
        } else {
            setError(err);
                toast.error("First fix the mistakes", {
                    position: "top-center"
                });
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-white">
            <div className="w-full max-w-md p-8 space-y-8 bg-white shadow-lg rounded-lg">
                <SignInHeader logoSrc={logo} title="Change Your Password" />
                <form onSubmit={handleChangePassword} className="space-y-4">
                    {error && <div className="text-red-500 text-sm">{error}</div>}
                    <input
                        type="password"
                        name="newPassword"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full p-2 border rounded"
                        required
                    />
                    <button
                        type="submit"
                        className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Change Password
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ResetPassword;
