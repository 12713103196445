import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateById } from '../../redux/actions/Recruiter/getCandidateById';
import Loading from '../../components/ProfileCandidates/Loading';
import ProfileHeader from '../../components/ProfileCandidates/ProfileCard';
import CandidateDetails from '../../components/ProfileCandidates/ProfileDetail';
import EditButton from '../../components/ProfileCandidates/EditButton';

const ProfileCandidates = () => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const candidate = useSelector(state => state.candidate);

  useEffect(() => {
    if (user && user.id) {
      dispatch(getCandidateById(user.id));
    }
  }, [user.id, dispatch]);

  if (!candidate) {
    return <Loading />;
  }

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 py-8">

        {/* Card principal */}
        <div className="bg-white p-8 rounded-b-lg shadow-xl max-w-4xl mx-auto">
          {/* Header del candidato */}
          <ProfileHeader candidate={candidate} />

          {/* Detalles del candidato */}
          <CandidateDetails candidate={candidate} />

          {/* Botón Edit */}
          <div className="flex justify-center mt-6">
            <EditButton candidateId={candidate.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCandidates;
