import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { technologyOptions } from './technologyOptions';
import { countryOptions } from './countryOptions';
import { interviewStageOptions } from './interviewStageOptions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify'; 
import validation from './validation';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { Comment } from 'react-loader-spinner'
import { validation2 } from './validations2';
import Loading from 'react-fullscreen-loading'


const JobRegisterForm = () => {
  const user = useSelector(state => state.user) || null;
  const [errors, setErrors] = useState({})
  const [jobDescription,setJobDescription] = useState('')
  const [responsibilities, setResponsibilities] = useState('')
  const [requestSendStatus, setRequestSendStatus] = useState(false)
  const [qualificationsAndSkills, setQualificationsAndSkills] = useState('')
  const [formData, setFormData] = useState({
    jobName: '',
    locationAvailability: [],
    salary: '',
    mustHaveTechnologies: [],
    niceToHaveTechnologies: [],
    yearsOfExperience: '',
    seniority: '',
    workingScheme: '',
    jobType: '',
    contractTerm: '',
    englishLevel: '',
    additionalInformation: '',
    interviewStages: [],
    userId: user.id,
    recruiterId: ''
  });

  const [buttonGPTIsEnabled, setButtonGPTIsEnabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'bullet' }],
  ]

  const handleQuillChange = (data,name) =>{
    const sanitizedData = DOMPurify.sanitize(data)
    if(name === 'jobDescription'){
      setJobDescription(sanitizedData)
    }else if(name === 'responsibilities'){
      setResponsibilities(sanitizedData)
    }else if(name === 'qualificationsAndSkills'){
      setQualificationsAndSkills(sanitizedData)
    }
  }


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleMultiSelectChange = (selectedOptions, name) => {
    setFormData({
      ...formData,
      [name]: selectedOptions ? selectedOptions.map(option => option.value) : []
    });
  };

  const handleSingleSelectChange = (selectedOption, name) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : ''
    });
  };


  const handleModalSubmit = async (e) => {
    e.preventDefault()

    const dataToSend = {
        jobName: formData.jobName,
        locationAvailability: formData.locationAvailability,
        salary: formData.salary,
        mustHaveTechnologies: formData.mustHaveTechnologies,
        niceToHaveTechnologies: formData.niceToHaveTechnologies,
        yearsOfExperience: formData.yearsOfExperience,
        seniority: formData.seniority,
        jobType: formData.jobType,
        workingScheme: formData.workingScheme,
        contractTerm: formData.contractTerm,
        englishLevel: formData.englishLevel,
        userId: user.id
    }

    const err = validation2(dataToSend)

    if (err === null) {
        try {
            setIsLoading(true)
            const response = await axios.post('/autocomplete-job-info', dataToSend)
            
            if (response.status === 200) {
                
                toast.success('Data generated successfull.',
                    {
                    position: "top-right",
                    autoClose: 3000,
                    onOpen: () => {
                        setJobDescription(response.data.response.jobDescription)
                        setQualificationsAndSkills(response.data.response.qualificationsAndSkills)
                        setResponsibilities(response.data.response.responsibilities)
                        setButtonGPTIsEnabled(false)
                        setIsLoading(false)
                    }
                    })
            }
            setErrors({})
        } catch (error) {
            setIsLoading(false)
            if (error.response) {
                toast.error(error.response.data.error);
            } else {
                toast.error("An error occurred in the request. Please try again.");
            }
        }
    }else{
        setErrors(err)
        toast.error("First fix the mistakes")
    }
    
}




  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestSendStatus(true)
      
    const err = validation({
      ...formData, 
      jobDescription, 
      responsibilities,
      qualificationsAndSkills
    })

    if (err === null) {
      try {
        const dataToSend = { ...formData,
          locationAvailability: formData.locationAvailability.join(', '), //Es un array primeramente luego se guarda con comas
          interviewStages: formData.interviewStages.join(', '),
          jobDescription: jobDescription,
          responsibilities: responsibilities,
          qualificationsAndSkills: qualificationsAndSkills
        };

        const response = await axios.post('/add-job', dataToSend);
        if (response.status === 200) {
          
          toast.success('Successfull.',
            {
              position: "top-right",
              autoClose: 3000,
              onOpen: () => {
                setRequestSendStatus(false)
                setFormData({
                  jobName: '',
                  locationAvailability: [],
                  salary: '',
                  mustHaveTechnologies: [],
                  niceToHaveTechnologies: [],
                  yearsOfExperience: '',
                  seniority: '',
                  workingScheme: '',
                  jobType: '',
                  contractTerm: '',
                  englishLevel: '',
                  additionalInformation: '',
                  interviewStages:[],
                  userId: user.id,
                  recruiterId: ''
                })
                setJobDescription('')
                setResponsibilities('')
                setQualificationsAndSkills('')
                setErrors({})
                setButtonGPTIsEnabled(true)
              }
            }
          )

        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message,{
            onOpen: () => {
              setRequestSendStatus(false)
            }
          });
        } else {
          toast.error("An error occurred in the request. Please try again.",{
            onOpen: () => {
              setRequestSendStatus(false)
            }
          });
        }
      }
    }else {
      setErrors(err)
      toast.error("First fix the mistakes",{
        onOpen: () => {
          setRequestSendStatus(false)
        }
      })
    }
  };






  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-blue-600">Complete your job registration</h1>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="col-span-1">
                <label className="block text-gray-700">Job Name <span className="text-red-500">*</span></label>
                {errors.jobName && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.jobName}</span>
                  </div>
                )}
                <input
                  type="text"
                  name="jobName"
                  placeholder="Job Name"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.jobName}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Salary (USD) <span className="text-red-500">*</span></label>
                {errors.salary && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.salary}</span>
                  </div>
                )}
                <input
                  type="text"
                  name="salary"
                  placeholder="Salary"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.salary}
                  onChange={handleChange}
                />
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Location Availability <span className="text-red-500">*</span></label>
                {errors.locationAvailability && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.locationAvailability}</span>
                  </div>
                )}
                <Select
                  isMulti
                  name="locationAvailability"
                  options={countryOptions}
                  className="w-full rounded-lg"
                  value={countryOptions.filter(option => formData.locationAvailability.includes(option.value))}
                  onChange={selectedOptions => handleMultiSelectChange(selectedOptions, 'locationAvailability')}
                />
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Must-Have Technologies <span className="text-red-500">*</span></label>
                {errors.mustHaveTechnologies && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.mustHaveTechnologies}</span>
                  </div>
                )}
                <Select
                  name="mustHaveTechnologies"
                  options={technologyOptions}
                  className="w-full rounded-lg"
                  value={technologyOptions.filter(option => formData.mustHaveTechnologies.includes(option.value))}
                  onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'mustHaveTechnologies')}
                  isMulti
                />
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Nice-to-Have Technologies</label>
                <Select
                  name="niceToHaveTechnologies"
                  options={technologyOptions}
                  className="w-full rounded-lg"
                  value={technologyOptions.filter(option => formData.niceToHaveTechnologies.includes(option.value))}
                  onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'niceToHaveTechnologies')}
                  isMulti
                />
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Years of Experience <span className="text-red-500">*</span></label>
                {errors.yearsOfExperience && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.yearsOfExperience}</span>
                  </div>
                )}
                <select
                  name="yearsOfExperience"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.yearsOfExperience}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select...</option>
                  <option value="6 Months - 1 Year">6 Months - 1 Year</option>
                  <option value="1 Year - 2 Years">1 Year - 2 Years</option>
                  <option value="3 Years - 4 Years">3 Years - 4 Years</option>
                  <option value="5 Years - 6 Years">5 Years - 6 Years</option>
                  <option value="7 Years - 8 Years">7 Years - 8 Years</option>
                  <option value="+9 Years">+9 Years</option>
                </select>
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Seniority <span className="text-red-500">*</span></label>
                {errors.seniority && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.seniority}</span>
                  </div>
                )}
                <select
                  name="seniority"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.seniority}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select...</option>
                  <option value="Trainee">Trainee</option>
                  <option value="Junior">Junior</option>
                  <option value="Mid">Mid</option>
                  <option value="Senior">Senior</option>
                  <option value="Architect">Architect</option>
                </select>
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Working Scheme <span className="text-red-500">*</span></label>
                {errors.workingScheme && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.workingScheme}</span>
                  </div>
                )}
                <select
                  name="workingScheme"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.workingScheme}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select...</option>
                  <option value="Remote">Remote</option>
                  <option value="On-site">On-site</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Job Type <span className="text-red-500">*</span></label>
                {errors.jobType && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.jobType}</span>
                  </div>
                )}
                <select
                  name="jobType"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.jobType}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select...</option>
                  <option value="Full-Time">Full-Time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Hourly">Hourly</option>
                </select>
              </div>

              <div className="col-span-1">
                <label className="block text-gray-700">Contract Term <span className="text-red-500">*</span></label>
                {errors.contractTerm && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.contractTerm}</span>
                  </div>
                )}
                <select
                  name="contractTerm"
                  className="w-full border border-gray-300 rounded-lg p-2"
                  value={formData.contractTerm}
                  onChange={handleChange}
                >
                  <option value="" disabled>Select...</option>
                  <option value="fixed-term">Fixed-term</option>
                  <option value="unfixed-term">Unfixed-term</option>
                </select>
              </div>

              <div className="col-span-1">
                  <label className="block text-gray-700">English Level <span className="text-red-500">*</span></label>
                  {errors.englishLevel && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                      <span>{errors.englishLevel}</span>
                    </div>
                  )}
                  <select
                    name="englishLevel"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.englishLevel}
                    onChange={handleChange}
                  >
                    <option value="" disabled>Select...</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                    <option value="C1">C1</option>
                    <option value="C2">C2</option>
                  </select>
                </div>

              </div>

              <div className="col-span-2">
                  <div className='flex items-end justify-end'>
                          {isLoading ? <Comment
                          visible={true}
                          height="55"
                          width="55"
                          ariaLabel="comment-loading"
                          wrapperStyle={{}}
                          wrapperClass="comment-wrapper"
                          color="#fff"
                          backgroundColor="#4E97D1"
                      />:null}
                      
                          {buttonGPTIsEnabled && (<button className='flex p-3 rounded-lg bg-purple-500 text-white hover:bg-purple-600' onClick={handleModalSubmit} disabled={isLoading} >Complete with AI (BETA) <FaWandMagicSparkles className="ml-5" size={23}/></button>)}
                          
                          
                      </div>

                  <label className="block text-gray-700">Job Description <span className="text-red-500">*</span></label>
                  {errors.jobDescription && (
                    <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.jobDescription}</span>
                </div>
                  )}
                  <ReactQuill
                    
                    value={jobDescription}
                    onChange={(data)=>handleQuillChange(data,'jobDescription')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="Write a description..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-gray-700">Responsibilities <span className="text-red-500">*</span></label>
                  {errors.responsibilities && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                      <span>{errors.responsibilities}</span>
                  </div>
                  )}

                  <ReactQuill
                    value={responsibilities}
                    onChange={(data)=>handleQuillChange(data,'responsibilities')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="Write responsibilities..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-gray-700">Qualifications and Skills</label>

                  <ReactQuill
                    value={qualificationsAndSkills}
                    onChange={(data)=>handleQuillChange(data,'qualificationsAndSkills')}
                    className="bg-white text-black rounded-lg h-40 mb-16"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="Write qualifications and skills..."
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-gray-700">Additional Information</label>
                  <textarea
                    name="additionalInformation"
                    placeholder="Additional Information"
                    className="w-full border border-gray-300 rounded-lg p-2"
                    value={formData.additionalInformation}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-span-2">
                <label className="block text-gray-700">Interview Stages (Please select by order.) <span className="text-red-500">*</span></label>
                {errors.interviewStages && (
                  <div className="bg-red-100 border border-red-400 text-red-700 p-1 rounded mb-1 relative" role="alert">
                    <span>{errors.interviewStages}</span>
                  </div>
                )}
                <Select
                  name="interviewStages"
                  options={interviewStageOptions}
                  className="w-full rounded-lg"
                  value={formData.interviewStages.map(stage => ({ value: stage, label: stage }))}
                  onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'interviewStages')}
                  isMulti
                />
              </div>

            <div className="text-center">
              <button className="bg-blue-500 text-white py-2 px-4 rounded w-full">Complete Register</button>
            </div>
          </form>
        </div>
        <ToastContainer />
        <Loading loading={requestSendStatus} background="rgba(0, 0, 0, 0.6)" loaderColor="#3498db" />
      </div>
    
  );
};

export default JobRegisterForm;

