import React from 'react';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa'; // Ícono de edición

const EditButton = ({ candidateId }) => (
  <div className="text-center mt-6">
    <Link 
      to={`/edit-profile/${candidateId}`} 
      className="inline-flex items-center bg-blue-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 hover:scale-105 transition-all duration-300 ease-in-out">
      <FaEdit className="mr-2 text-lg" /> {/* Ícono de edición */}
      Edit Profile
    </Link>
  </div>
);

export default EditButton;
