import { useEffect, useState } from "react";
import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FaArrowRightLong, FaWandMagicSparkles } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'react-fullscreen-loading'

const ReportChallenge = () => {
  const user = useSelector(state => state.user)
  const { candidateId, jobId } = useParams();
  const [report, setReport] = useState(null);
  const [requestSendStatus, setRequestSendStatus] = useState(false)

  // feedBackWithAI Incluye null o el objeto


  useEffect(() => {
    if (candidateId || jobId) {
      axios.get(`/completed-test/${candidateId}/${jobId}`).then((response) => {
        const fetchedReport = response.data;
        setReport(fetchedReport);
      });
    }
  }, [candidateId, jobId]);

  const handleFeedbackClick = async () => {
    try {
      setRequestSendStatus(true)

      const response = await axios.put(`/recommendation-candidate/${report.id}`, {userId: user.id});
      
      if (response.status === 200) {
        toast.success("Recommendation generated successfully",{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
        const updatedReport = await axios.get(`/completed-test/${candidateId}/${jobId}`);        
        setReport({...report, feedBackWithAI: updatedReport.data.feedBackWithAI});
      }else{
        throw new Error("An error occurred in the request. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message,{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
      } else {
        toast.error("An error occurred in the request. Please try again.",{
          onOpen: () => {
            setRequestSendStatus(false)
          }
        });
      }
    }
  };

  if (!report) {
    return <>Loading</>;
  }

  const COLORS = ["#22C55E", "#EF4444"];
  const pieData = [
    { name: "Correct", value: report.technicalScore },
    { name: "Incorrect", value: 10 - report.technicalScore },
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-full">
        {/* User Information */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-800">{report.name}</h2>
            <p className="text-md text-gray-500">{report.email}</p>
          </div>

          <div>
            <p className="text-lg font-medium text-gray-700">
              Score: <span className="text-primary">{report.technicalScore} / 10 </span>
            </p>
          </div>
        </div>

        <div>
          <Link target="_blank" to={`/candidate-detail/${candidateId}`}>
            <button className="bg-blue-600 p-2 rounded-lg text-white flex justify-center items-center hover:bg-blue-500">
              Visit candidate profile
              <FaArrowRightLong style={{ marginLeft: "10px" }} />
            </button>
          </Link>
        </div>

        {/* Button to trigger feedback */}
        {report.feedBackWithAI === null && (
          <div className="flex justify-center mt-6">
            <button className='flex p-3 rounded-lg bg-purple-500 text-white hover:bg-purple-600' 
              onClick={handleFeedbackClick} 
            >Generate Recommendation (BETA)<FaWandMagicSparkles className="ml-3" size={23}/></button>
          </div>
        )}

        {/* Recommendation Section */}
        { report.feedBackWithAI != null && (
          <div className="mt-6 p-4 bg-gray-100 border border-gray-300 rounded-lg shadow">
            <h3 className="text-lg font-semibold text-gray-800">Recommendation:</h3>
            <div className="mt-4">
              <h4 className="text-md font-medium text-green-700">Good Features:</h4>
              <p className="text-gray-700 mt-1">{report.feedBackWithAI.goodFeatures}</p>
            </div>
            <div className="mt-4">
              <h4 className="text-md font-medium text-red-700">Bad Features:</h4>
              <p className="text-gray-700 mt-1">{report.feedBackWithAI.badFeatures}</p>
            </div>
            <div className="mt-4">
              <h4 className="text-md font-medium text-blue-700">Recommendation IA (BETA):</h4>
              <p className="text-gray-700 mt-1">{report.feedBackWithAI.OpinionOfAI}</p>
            </div>
          </div>
        )}

        {/* Chart */}
        <div className="flex justify-center mb-10 mt-6">
          <div className="w-48 h-48">
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieData}
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Technical Test */}
        <div className="mb-10">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Technical Test
          </h3>
          <div className="space-y-4">
            {report.technicalTest.map((answer, index) => (
              <div
                key={index}
                className={`flex items-center justify-between p-4 rounded-lg border ${
                  answer.correct
                    ? "bg-green-50 border-green-200"
                    : "bg-red-50 border-red-200"
                }`}
              >
                <div className="flex items-center">
                  {answer.correct ? (
                    <CheckCircleIcon className="h-6 w-6 text-green-500" />
                  ) : (
                    <XCircleIcon className="h-6 w-6 text-red-500" />
                  )}
                  <p className="ml-4 text-gray-700">{answer.question}</p>
                </div>
                <p className="text-gray-500 italic">Selected: {answer.answer}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Open-Ended Questions */}
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">
            Psychometric Test
          </h3>
          <div className="space-y-6">
            {report.psychometricTest.questions.map((item, index) => (
              <div
                key={index}
                className="bg-gray-50 p-6 rounded-lg shadow-sm border border-gray-200"
              >
                <p className="text-gray-600 font-semibold mb-2">Question:</p>
                <p className="text-gray-800 font-medium mb-3">{item.question}</p>
                <p className="text-gray-600 font-semibold mb-2">Answer:</p>
                <p className="text-gray-800">{item.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ToastContainer />
      <Loading loading={requestSendStatus} background="rgba(0, 0, 0, 0.6)" loaderColor="#3498db" />
    </div>
  );
};

export default ReportChallenge;
