import React from 'react';
import { FaMapMarkerAlt, FaBriefcase } from 'react-icons/fa'; // Iconos de ubicación y experiencia

const ProfileHeader = ({ candidate }) => (
  <div className="text-center mb-6">
    {/* Nombre y Apellido */}
    <h2 className="text-2xl font-semibold text-gray-900">
      {candidate.firstName} {candidate.lastName}
    </h2>

    {/* Ubicación y País con icono */}
    <p className="text-blue-500 flex justify-center items-center space-x-2">
      <FaMapMarkerAlt className="text-lg" />
      <span>{candidate.location}, {candidate.country}</span>
    </p>

    {/* Descripción de la experiencia con icono */}
    <p className="text-gray-700 mt-2 flex justify-center items-center space-x-2">
      <FaBriefcase className="text-lg" />
      <span>{candidate.experienceDescription}</span>
    </p>
  </div>
);

export default ProfileHeader;

